import type { ComponentType } from "react"

import {
    getFirestore,
    collection,
    doc,
    addDoc,
    onSnapshot,
} from "firebase/firestore"
import { useAuth } from "https://framer.com/m/hooks-cRhB.js"

import { app, auth, db, functions } from "https://framer.com/m/config-1Ws6.js"

import { httpsCallable } from "firebase/functions"

import { toast } from "react-hot-toast"

export function checkoutSessiont(Component): ComponentType {
    return (props) => {
        const { user, isLoggedIn, loading, error } = useAuth()
        const checkoutSessionsRef = collection(
            db,
            "users",
            user.uid,
            "checkout_sessions"
        )

        const createCheckoutSession = async () => {
            try {
                const docRef = await addDoc(checkoutSessionsRef, {
                    collect_shipping_address: false,
                    price: "price_1Mu7GvHcJxANJfkl14bMIwzO",
                    success_url: `${window.location.origin}/wellcome`,
                    cancel_url: window.location.origin,
                    metadata: {
                        firebaseUid: user.uid,
                    },
                })

                onSnapshot(
                    doc(db, "users", user.uid, "checkout_sessions", docRef.id),
                    (snap) => {
                        const { error, url } = snap.data()
                        if (error) {
                            alert(`An error occured: ${error.message}`)
                        }
                        if (url) {
                            console.log(url)
                            window.location.assign(url)
                        }
                    }
                )
            } catch (error) {
                console.log("Error adding document: ", error)
            }
        }

        const handleClick = async () => {
            toast.promise(createCheckoutSession(), {
                loading: "Redirecinando...",
                success: <b></b>,
                error: <b>Error ao ir para o Checkout! Tente novamente.</b>,
            })
        }

        return <Component {...props} onClick={handleClick} />
    }
}

export function portalSession(Component): ComponentType {
    return (props) => {
        const createPortalLink = async () => {
            const functionRef = httpsCallable(
                functions,
                "ext-firestore-stripe-payments-createPortalLink"
            )
            const { data } = await functionRef({
                returnUrl: window.location.origin,
                locale: "auto", // Optional, defaults to "auto"
            })
            window.location.assign(data.url)
        }

        const handleClick = async () => {
            toast.promise(createPortalLink(), {
                loading: "Saving...",
                success: <b></b>,
                error: <b>Error ao ir para o Checkout! Tente novamente.</b>,
            })
        }

        return <Component {...props} onClick={handleClick} />
    }
}
